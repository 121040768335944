body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-drag: none;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.Toastify__toast-container--top-center  {
 
  padding: 3px 10% ;
  z-index: 9999;
  margin: 10px;
}
.Toastify__toast-container--top-center > .Toastify__toast--success {

  border-radius: 8px !important;
  background: #30343C !important;
  color: #ffff;
} 
.Toastify__toast-container--top-center > .Toastify__toast--success  svg {
 fill: #ffff;
 
} 
.toast-body{
  padding: 2px 8px!important;
  min-height: 50px !important;
}
.Toastify__toast-theme--dark {
  background-color:  #30343C ;
  border-radius: 8px;
  margin:  5px 8%;
  padding: 5px 5px;
}
.Toastify__toast-theme--dark .Toastify__progress-bar {
  background-color: #fff !important;
}

@media screen and (max-width: 1000px) {
  * {
    font-size: 10px;
  }
}
#root {
  white-space: pre-line;
  -webkit-user-drag: none;
}