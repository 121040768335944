.rdrMonthAndYearPickers {
  flex-direction: row-reverse;
}
.rdrMonthName {
  font-family: sans-serif;
  color: #111111 !important;
  font-weight: 500 !important;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
text-align: center;
}
